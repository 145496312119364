/**
* Template Name: Top Taxi
* Updated: 17.7.2023.
* Author: MVSoft Services
*/

@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "bootstrap-icons/font/bootstrap-icons";
@import "remixicon/fonts/remixicon.css";
@import "glightbox/dist/css/glightbox.min.css";
@import "boxicons/css/boxicons.min.css";
@import "animate.css/animate.min.css";

:root {
    --color-primary: #0f0f0f;
    --color-secondary: #f5f5f5;
    --color-red: #f10812;
    background: var(--color-primary);
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #0f0f0f;
    font-size: 18px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Open Sans", sans-serif;
}

.btn {
    padding: 16px 40px !important;
    border-radius: 64px !important;
    border-color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
}

.btn-primary {
    background: var(--color-primary);
}

.custom-btn {
    color: white;
}

.custom-btn-black {
    color: black;
}

.card-btn:hover .custom-btn-black {
    color: #fff;
    border-color: var(--color-primary);
}

.card-btn:hover .custom-btn {
    color: var(--color-primary) !important;
}

.btn-primary:hover {
    background: #fff !important;
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:hover .custom-btn {
    color: var(--color-primary);
}

.btn-secondary {
    color: var(--color-primary);
    background: var(--color-secondary);
}

.btn-secondary:hover {
    color: #fff !important;
    background: var(--color-primary) !important;
    border-color: var(--color-primary);
}

.contact-us-btn div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding: 6px;
    background: #fff;
    color: var(--color-primary);
    font-size: 18px;
    border-radius: 50%;
}

.contact-us-btn {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    padding-left: 45px;
    padding-right: 5px;
}

.contact-us-btn:before {
    content: "\f054";
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding: 6px;
    box-sizing: content-box;
    background: #fff;
    color: var(--color-primary);
    font-size: 14px;
    border-radius: 50%;
    transition: 0.2s;
}

.contact-us-btn.btn-phone:before {
    content: "\f095";
}

.contact-us-btn:hover:before {
    transform: translateX(6px) rotate(90deg);
    transition: 0.3s;
}

.card {
    border-radius: 24px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: var(--color-primary);
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid var(--color-secondary);
    border-top-color: var(--color-primary);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.7);
    background-image: url("../../../public/website/images/mb-logo.svg");
    background-size: 105%;
    background-position: center;
    background-repeat: no-repeat;
    animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: var(--color-primary);
    border: 1px solid var(--color-secondary);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    transition: all 0.3s;
}

.back-to-top i {
    font-size: 28px;
    line-height: 0;
}

.back-to-top:hover {
    background: var(--color-secondary);
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

.datepicker-dropdown {
    padding: 20px !important;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: var(--color-primary);
    transition: all 0.3s;
    z-index: 997;
    padding: 15px 0;
    top: 40px;
}

#header.header-scrolled {
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: "Open Sans", sans-serif;
}

#header .logo a {
    color: #fff;
}

#header .logo img {
    max-height: 40px;
}

#header #language-select select {
    background-color: var(--color-primary);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    border: none;
    outline: none;
}

#header #language-select:before {
    content: "\F3EF";
    font-family: "bootstrap-icons";
    position: relative;
    top: 1px;
    color: #fff;
    font-size: 10px;
}

@media (max-width: 576px) {
    #header {
        top: 0;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 8px 0 8px 20px;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    padding: 5px 2px;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: var(--color-red);
}

.dropdown.language-select {
    background-color: var(--color-primary);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.dropdown.language-select:before {
    content: "\F3EF";
    font-family: "bootstrap-icons";
    position: relative;
    top: 1px;
    right: 6px;
    color: #fff;
    font-size: 10px;
}
.dropdown.language-select ul {
    display: block;
    position: absolute;
    top: 100%;
    left: 25px;
    padding: 8px 0;
    margin: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    list-style-type: none;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    transition: 0.2s;
}
.dropdown.language-select ul a {
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #fff;
    border: none;
    transition: 0.2s;
}
.dropdown.language-select ul a i {
    font-size: 12px;
}
.dropdown.language-select ul a:hover,
.dropdown.language-select ul .active:hover,
.dropdown.language-select ul li:hover > a {
    color: #fff;
}
.dropdown.language-select:hover > ul {
    opacity: 1;
    top: 100%;
    left: 25px;
    visibility: visible;
    background: var(--color-primary);
}
.dropdown.language-select:hover > ul li {
    width: fit-content;
    min-width: auto;
    color: #fff;
}
.dropdown.language-select:hover > ul li a {
    color: #fff;
}
.dropdown.language-select:hover > ul li a:hover {
    color: var(--color-red);
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 20px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #082744;
    border: none;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #1977cc;
}

.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: var(--color-red);
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: var(--color-primary);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    left: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: var(--color-primary);
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile > ul > li {
    padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #fff;
    border: none;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: var(--color-red);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #fff;
}

.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 90vh;
    background: url("../../../public/website/images/hero-bg-trans.png") center right;
    background-repeat: no-repeat;
    background-color: var(--color-primary);
    margin-bottom: -130px;
}

#hero .container {
    position: relative;
}

#hero h1 {
    margin: 0;
    font-size: 61px;
    font-weight: 700;
    line-height: 73px;
    color: #fff;
    text-shadow: 0 0 8px #000;
}

#hero h2 {
    color: #fff;
    margin: 10px 0 0 0;
    font-size: 24px;
    text-shadow: 0 0 8px #000;
}

#hero p {
    max-width: 540px;
    margin-top: 60px;
    color: #fff;
    text-shadow: 0 0 8px #000;
    padding-bottom: 30px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

@media (min-width: 992px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 1200px) {
    #hero {
        background-size: auto 40%;
    }
}

@media (max-width: 992px) {
    #hero {
        padding-top: 10vh;
        height: 100vh;
        /* background-position: bottom; */
        background-position: center calc(100% - 15vh);
        background-size: contain;
    }

    #hero .container {
        padding-bottom: 63px;
    }

    #hero h1 {
        font-size: 30px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}

@media (max-height: 600px) {
    #hero {
        height: 110vh;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 90px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f1f7fd;
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 62px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: var(--color-primary);
}

.section-title p {
    margin-bottom: 33px;
    max-width: 720px;
    line-height: 160%;
    letter-spacing: -0.36px;
}

@media (max-width: 992px) {
    .section-title h2 {
        font-size: 36px;
        line-height: 48px;
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 20px 0;
    background-color: #f1f7fd;
    min-height: 40px;
    margin-top: 120px;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 100px;
    }
}

.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
}

@media (max-width: 992px) {
    .breadcrumbs h2 {
        margin: 0 0 10px 0;
    }
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
# Find vehicle
--------------------------------------------------------------*/
.find-vehicle {
    padding: 60px 0;
}

.find-vehicle form {
    background: var(--color-secondary);
    padding: 25px;
    border-radius: 16px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    backdrop-filter: blur(14px);
}

.find-vehicle form label {
    display: block;
    min-height: 24px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.find-vehicle form input {
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
}

.find-vehicle form input:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 0.25rem rgba(15, 15, 15, 0.25);
}

.find-vehicle form .search-input {
    position: relative;
    min-width: 240px;
}

.find-vehicle form .search-input:after {
    content: "\F3E8";
    font-family: "bootstrap-icons";
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 16px;
    height: 100%;
    color: #000;
    font-size: 16px;
}

.find-vehicle form .email-input {
    position: relative;
    min-width: 240px;
}

.find-vehicle form .email-input:after {
    content: "\F32F";
    font-family: "bootstrap-icons";
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 16px;
    height: 100%;
    color: #000;
    font-size: 16px;
}

.find-vehicle form .email-input input {
    padding-left: 45px;
}

.find-vehicle form .search-input input {
    padding-left: 45px;
}

.find-vehicle form button {
    font-size: 16px;
    font-weight: 700;
    min-width: max-content;
}

@media (max-width: 576px) {
    .find-vehicle form label {
        margin-bottom: 24px;
    }
}

/*--------------------------------------------------------------
# How It Works
--------------------------------------------------------------*/
.how-it-works .container {
    z-index: 1;
}

.how-it-works .section-title p {
    font-style: italic;
}

.how-it-works .section-cards {
    margin-top: 45px;
}

.how-it-works .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 170px;
    padding: 32px;
    margin-bottom: 16px;
}

.how-it-works .card__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 54px;
    height: 106px;
    margin-right: 32px;
    border-radius: 16px;
    background: var(--color-secondary);
    font-size: 24px;
}

.how-it-works .card__text-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
}

.how-it-works .background-shape {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 270px;
    left: 0;
    height: calc(100% - 270px);
    width: 100%;
}

.how-it-works .background-shape .background-shape__shape {
    margin-right: 10%;
    width: 50%;
    height: 715px;
    background: var(--color-secondary);
    border-radius: 24px;
}

.how-it-works .background-img {
    top: 270px;
    left: 0;
    height: calc(100% - 270px);
    width: 100%;
    background: url("../../../public/website/images/how-it-works-bg.png") center right;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 1;
}

@media (max-width: 1400px) {
    .how-it-works .background-shape .background-shape__shape {
        width: 60%;
    }

    .how-it-works .background-img {
        background-size: auto 65%;
    }
}

@media (max-width: 1200px) {
    .how-it-works .background-img {
        background-size: auto 50%;
    }
}

@media (max-width: 992px) {
    .how-it-works .background-shape {
        position: relative !important;
        top: -100px;
    }

    .how-it-works .background-shape .background-shape__shape {
        width: 100%;
        height: 600px;
        margin: 0 10%;
    }

    .how-it-works .background-img {
        background-position: bottom;
        background-size: contain;
    }

    .how-it-works .card__text-title {
        font-size: 19px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .how-it-works .background-shape .background-shape__shape {
        height: 400px;
        margin: 0 10%;
    }
}

@media (max-width: 576px) {
    .how-it-works .background-shape .background-shape__shape {
        height: 300px;
        margin: 0 10%;
    }
}

@media (max-width: 480px) {
    .how-it-works .background-shape .background-shape__shape {
        height: 180px;
        margin: 0 10%;
    }

    .how-it-works .background-img {
        z-index: 1;
    }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
    background: var(--color-primary);
}

.services h2,
.services p {
    color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    background: url("../../../public/website/images/about-us-bg.png") bottom right;
    background-repeat: no-repeat;
}

@media (max-width: 992px) {
    .about {
        padding-bottom: 550px;
        background-size: contain;
    }
}

@media (max-width: 768px) {
    .about {
        padding-bottom: 450px;
    }
}

@media (max-width: 576px) {
    .about {
        padding-bottom: 350px;
    }
}

@media (max-width: 480px) {
    .about {
        padding-bottom: 250px;
    }
}

.about .section-title {
    align-items: flex-start;
    text-align: left;
}

.about .section-title p {
    max-width: 100%;
}

.about .section-title p:nth-of-type(3) {
    max-width: 800px;
}

.about .section-title p:last-of-type {
    max-width: 700px;
}

.about .check-boxes,
.about .check-logo {
    border: 1px solid var(--color-primary);
    border-radius: 25px;
    min-height: 170px;
}

.about .check-boxes {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 20px;
    background: #fff;
}

.about .check-boxes ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 992px) {
    .about .check-boxes ul {
        font-size: 12px;
        font-weight: 400;
    }
}

.about .check-boxes ul li {
    margin-bottom: 10px;
}

.about .check-boxes ul li:last-of-type {
    margin-bottom: 0;
}

.about .check-boxes ul input {
    margin-right: 14px;
}

.about .check-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 170px;
    background: var(--color-secondary);
}

.about .check-logo__logo {
    margin-bottom: 12px;
}

.about .check-logo__text {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
}

.about .check-boxes li {
    display: flex;
    align-items: center;
}

.about .check-boxes i {
    font-size: 25px;
    margin-right: 12px;
}

/*--------------------------------------------------------------
# Our Cars
--------------------------------------------------------------*/
.our-cars {
    background: var(--color-secondary);
}

.our-cars .section-title p {
    font-style: italic;
    font-weight: 400;
}

.our-cars .section-title ul li {
    font-weight: 600;
    margin-bottom: 5px;
}

.our-cars .vehicle-badge {
    width: 140px;
    padding: 12px 19px;
    margin-bottom: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 58px;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    cursor: pointer;
}

.our-cars .badge-primary {
    background: var(--color-primary);
    color: #fff;
}

.our-cars .badge-secondary {
    background: #fff;
    color: var(--color-primary);
}

.our-cars .vehicle-cards {
    margin-top: 70px;
}

.our-cars .vehicle-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px 37px 12px;
    margin-bottom: 32px;
    background: #fff;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.our-cars .vehicle-card:hover {
    border: 1px solid var(--color-primary);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 8px 24px 0px rgba(16, 24, 40, 0.08);
}

.our-cars .vehicle-card .card-body {
    flex: unset;
}

.our-cars .vehicle-card h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.our-cars .vehicle-card h3 span {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
}

.our-cars .vehicle-card h4 {
    margin-top: 14px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.our-cars .vehicle-card .card-icons {
    display: flex;
    background: var(--color-secondary);
    border-radius: 16px;
    padding: 8px 16px;
    min-width: 318px;
    margin-top: 16px;
}

.our-cars .vehicle-card .card-icons .card-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 25px;
}

.our-cars .vehicle-card .card-icons .card-icon:last-of-type {
    margin-right: 0;
}

.our-cars .vehicle-card .card-icons .card-icon .icon-text {
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    margin-top: 6px;
}

.our-cars .vehicle-card .card-btn {
    min-width: 318px;
    margin-top: 14px;
}

#youtubeModal .modal-content {
    padding: 12px;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
#youtubeModal .modal-dialog {
    top: 40%;
    transform: translateY(-50%);
}
#youtubeModal .modal-content .btn-close {
    position: absolute;
    background: #fff;
    top: -10px;
    right: -10px;
    font-size: 25px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    z-index: 1;
    opacity: 1;
    border: 2px solid var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}
#youtubeModal .modal-content .btn-close:hover {
    background: var(--color-primary);
    color: #fff;
    box-shadow: 0 0 0 2px #fff;
}
#youtubeModal .modal-body {
    padding: 0;
}
#youtubeModal .iframe-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
#youtubeModal .iframe-container iframe {
    border-radius: 20px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .section-title h2 {
    padding-bottom: 0;
}

.contact .section-title p {
    font-style: italic;
}

.contact .contact-info {
    padding: 30px;
    height: 100%;
    background: var(--color-secondary);
    border-radius: 5px;
}

.contact .contact-info h3 {
    margin-bottom: 30px;
    font-size: 21px;
    font-weight: 700;
}

.contact .contact-info h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
}

.contact .contact-info h6 {
    font-size: 12px;
    font-weight: 400;
}

.contact .contact-info p {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 600;
}

.contact .contact-map h6 {
    margin-top: 20px;
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 700;
}

.contact .contact-form {
    background: var(--color-secondary);
    padding: 30px;
    border-radius: 5px;
}

.contact .contact-form h3 {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 700;
}

.contact .contact-form p {
    margin-bottom: 28px;
}

.contact .php-email-form .form-group {
    padding: 0px 7px 15px 7px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    min-height: 63px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    /* resize: none; */
}

.contact .form-control:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 0.25rem rgba(15, 15, 15, 0.25);
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    padding: 20px 50px;
    /* border: solid #dee2e6 1px; */
}

.contact .form-submit {
    padding: 0px 7px 15px 7px;
}

.contact button[type="submit"] {
    width: 100%;
    padding: 20px 0;
    font-size: 21px;
    font-weight: 600;
    border-radius: 5px !important;
    white-space: nowrap;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .section-title {
    align-items: flex-start;
    text-align: left;
    padding-bottom: 0;
}

.testimonials .section-title h2 {
    margin-bottom: 70px;
    padding-bottom: 0;
    font-size: 40px;
    font-weight: 600;
}

@media (max-width: 992px) {
    .testimonials .section-title h2 {
        font-size: 36px;
        line-height: 48px;
        max-width: 256px;
    }
}

/* .testimonials .testimonial-item {
    box-sizing: content-box;
} */

.testimonials .testimonial-item .testimonial-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.testimonials .testimonial-item h3 {
    font-size: 24px;
    font-weight: 700;
}

.testimonials .testimonial-item h4 {
    font-size: 20px;
    font-weight: 600;
}

.testimonials .testimonial-item p {
    font-size: 21px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.42px;
}

.testimonials .swiper {
    position: unset;
}

.testimonials .swiper .testimonials-navigation {
    top: 0;
    right: calc(var(--bs-gutter-x) * 0.5);
}

.testimonials .swiper .testimonials-navigation > [class^="testimonials-swiper-button"] {
    width: 64px;
    height: 64px;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    transition: 0.3s;
}

@media (max-width: 992px) {
    .testimonials .swiper .testimonials-navigation > [class^="testimonials-swiper-button"] {
        width: 38px;
        height: 38px;
    }

    .testimonials .swiper .testimonials-navigation > [class^="testimonials-swiper-button"] i {
        font-size: 16px;
    }
}

.testimonials .swiper .testimonials-navigation .testimonials-swiper-button-prev {
    background: #fff;
    color: var(--color-primary);
}

.testimonials .swiper .testimonials-navigation .testimonials-swiper-button-prev:hover {
    background: var(--color-primary);
    color: #fff;
}

.testimonials .swiper .testimonials-navigation .testimonials-swiper-button-next {
    background: var(--color-primary);
    color: #fff;
}

.testimonials .swiper .testimonials-navigation .testimonials-swiper-button-next:hover {
    background: #fff;
    color: var(--color-primary);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: #fff;
    font-size: 14px;
    background: var(--color-primary);
}

#footer .footer-top {
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
}

#footer .footer-newsletter {
    font-size: 15px;
}

#footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    padding-bottom: 12px;
}

#footer .social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .social-links a:hover {
    color: #fff;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Find vehicle correction
--------------------------------------------------------------*/
#cars {
    height: 60px;
}

/* @media (max-width: 1400px) {
    .find-vehicle form button {
    }
} */

@media (max-width: 1200px) {
    #cars {
        width: 100%;
    }

    .submit-div {
        display: flex;
        justify-content: center;
    }
}

/* @media (max-width: 576px) {
    .submit-div {
        margin-top: 0 !important;
    }
} */

.our-cars .border-class {
    border: 1px solid var(--color-primary);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 8px 24px 0px rgba(16, 24, 40, 0.08);
}
